<template>
  <div>
    <b-tabs pills>
      <b-tab>
        <template #title>
          <span>Assinar Documentos PDF</span>
        </template>
        <AssinaturaDigitalTab />
      </b-tab>
      <b-tab>
        <template #title>
          <span>Assinatura em Lote</span>
        </template>
        <AssinaturaEmLoteTab />
      </b-tab>
      <b-tab>
        <template #title>
          <span>Configurar Assinatura</span>
        </template>
        <ConfigurarAssinaturaDigitalTab />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import AssinaturaDigitalTab from './AssinaturaDigitalTab.vue'
  import ConfigurarAssinaturaDigitalTab from './configurarAssinaturaDigitalTab.vue'
  import AssinaturaEmLoteTab from './AssinaturaEmLoteTab/AssinaturaEmLoteTab.vue'

  export default {
    components: {
      AssinaturaDigitalTab,
      ConfigurarAssinaturaDigitalTab,
      AssinaturaEmLoteTab,
    },
  }
</script>
<style></style>
