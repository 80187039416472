<template>
  <div>
    <b-card>
      <header>
        <b-row>
          <b-col md="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input id="descricao" placeholder="Descrição" v-model="pesquisar.descricao" />
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button :disabled="isBusy" variant="primary" style="margin-top: 21px; width: 160px" @click="pesquisarContratos()">
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto" align="right">
            <b-button
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="assinarContratos()"
              :disabled="hasPermissao()"
            >
              Assinar
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="text-danger">
              Atenção: Os itens com status 'Pendente' que estão desabilitados não tiveram a minuta gerada.
            </p>
          </b-col>
        </b-row>
      </header>

      <b-table
        v-if="items.length > 0"
        :busy="isBusy"
        :fields="fields"
        :items="items"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #head(checkbox)="data">
          <b-form-checkbox v-model="allSelected" @change="toggleSelections">
            <span style="font-size: 11px">Selecionar todos</span></b-form-checkbox
          >
        </template>

        <template #cell(checkbox)="data">
          <b-form-checkbox
            v-model="selectedItems"
            :value="data.item.id"
            :disabled="
              carregarStatus(data.item.LogAssinaturaContrato[0]) == 'Assinado' || data.item.documentacaoProcesso.length <= 0
                ? true
                : false
            "
          >
          </b-form-checkbox>
        </template>

        <template #cell(codigo)="data">
          <span
            class="text-primary tw-cursor-pointer"
            @click="
              downloadDocumento(
                data.item.documentacaoProcesso && data.item.documentacaoProcesso.length
                  ? data.item.documentacaoProcesso[0].s3Key
                  : null,
              )
            "
            v-b-tooltip.hover.bottom="'Download do contrato'"
            >{{ data.item.codigo }}</span
          >
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="carregarStatus(data.item.LogAssinaturaContrato[0]) == 'Pendente' ? 'warning' : 'success'"
            class="text-white"
          >
            <span>{{ carregarStatus(data.item.LogAssinaturaContrato[0]) }}</span>
          </b-badge>
        </template>
      </b-table>

      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarValorReais } from '@/libs/utils'
  import { showLoading, closeLoading } from '@/utils/components/loading.js'
  import saveAs from 'file-saver'
  export default {
    props: {
      tipoTestemunha: String,
    },

    data() {
      return {
        isBusy: false,
        formatarData,
        formatarValorReais,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'Numero do contrato' },
        ],
        pageOptions: [10, 25, 50, 100],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
        },
        totalRows: 1,
        currentPage: 1,
        perPage: 100,
        fields: [
          { key: 'checkbox', label: '' },
          { key: 'proposta.dataAprovado', label: 'Data de Início', formatter: (v) => this.formatarData(v) },
          { key: 'codigo', label: 'Nº Contrato' },
          { key: 'valorComIOF', label: 'Valor do Contrato', formatter: (v) => this.formatarValorReais(v) },
          { key: 'segurado.tomador.nomeCompleto', label: 'Nome', sortable: true },
          { key: 'segurado.matricula', label: 'Matrícula' },
          { key: 'segurado.situacaoPrevidenciaria', label: 'Categoria' },
          { key: 'status', label: 'status' },
        ],
        items: [],
        selectedItems: [],
        allSelected: false,
        testemunhas: {},
      }
    },

    async mounted() {
      this.getTestemunhas()
    },

    methods: {
      toggleSelections() {
        if (this.allSelected) {
          this.selectedItems = this.items
            .filter(
              (item) =>
                this.carregarStatus(item.LogAssinaturaContrato?.[0]) !== 'Assinado' && item.documentacaoProcesso.length > 0,
            )
            .map((item) => item.id)
        } else {
          this.selectedItems = []
        }
      },

      async pesquisarContratos() {
        this.pesquisar = {
          ...this.pesquisar,
          tipoTestemunha: this.tipoTestemunha,
          institutoId: this.userData.institutoSelecionado,
          userId: this.userData.id,
        }

        this.isBusy = true
        try {
          const response = await useJwt.post('assinatura/getContratos', this.pesquisar)
          this.items = response.data
          this.totalRows = this.items.length
        } catch (error) {
          this.$message.error('Houve um erro ao carregar informações!')
        } finally {
          this.isBusy = false
        }
      },

      async assinarContratos() {
        try {
          if (this.selectedItems.length == 0) return this.$message.error('Selecione ao menos um contrato para assinar!')

          showLoading('Aguarde...', 'Realizando assinatura, isso pode demorar um pouco')

          const param = {
            contratosId: this.selectedItems,
            tipoTestemunha: this.tipoTestemunha,
            institutoId: this.userData.institutoSelecionado,
            urlDoUsuario: `${window.location.origin}/AssinaturaDocumento`,
          }

          const response = await useJwt.post('assinatura/postAssinaturaEmLote', param)

          if (response && response.data) {
            this.$message.success('Documento(s) assinado(s) com sucesso!')
          }
        } catch (error) {
          console.error('Erro ao realizar assinatura(s):', error)
          this.$message.error('Houve um erro ao realizar assinatura(s)')
        } finally {
          closeLoading()
          this.selectedItems = []
          await this.pesquisarContratos()
        }
      },

      carregarStatus(log) {
        if (!log) return 'Pendente'

        switch (this.tipoTestemunha) {
          case '1':
            if (log.dataAssinaturaGestor) return 'Assinado'
            else return 'Pendente'
          case '2':
            if (log.dataAssinaturaPrimeiraTestemunha) return 'Assinado'
            else return 'Pendente'
          case '3':
            if (log.dataAssinaturaSegundaTestemunha) return 'Assinado'
            else return 'Pendente'
        }
      },

      downloadDocumento(s3Key) {
        if (s3Key) {
          useJwt
            .post('aws/getDownladArquivoAwsCrypt', { s3Key })

            .then((response) => {
              const data = response.data
              const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
              const blob = new Blob([buffer], { type: data.ContentType })
              saveAs(blob, 'Documento-Assinado.pdf')
              const url = window.URL.createObjectURL(blob)
              window.open(url, '_blank')
              setTimeout(() => window.URL.revokeObjectURL(url), 100)
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Erro ao baixar o documento de baixa')
            })
        } else {
          this.$message.error('Documento não encontrado')
        }
      },
      hasPermissao() {
        const userAtual = this.userData.id
        switch (this.tipoTestemunha) {
          case '1':
            return userAtual !== this.testemunhas.gestorId
          case '2':
            return userAtual !== this.testemunhas.testemunhaUmId
          case '3':
            return userAtual !== this.testemunhas.testemunhaDoisId
          default:
            return true
        }
      },

      async getTestemunhas() {
        await useJwt.get(`assinatura/getTestemunhas/${this.userData.institutoSelecionado}`).then((response) => {
          this.testemunhas = {
            gestorId: response.data.gestor?.id || null,
            testemunhaUmId: response.data.testemunha1?.id || null,
            testemunhaDoisId: response.data.testemunha2?.id || null,
          }
        })
      },
    },
  }
</script>
<style></style>
