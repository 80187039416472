<template>
  <div>
    <b-tabs>
      <b-tab>
        <template #title>
          <span>Gestor</span>
        </template>
        <AssinaturaEmLote tipoTestemunha="1" />
      </b-tab>
      <b-tab>
        <template #title>
          <span>Testemunha 1</span>
        </template>
        <AssinaturaEmLote tipoTestemunha="2" />
      </b-tab>
      <b-tab>
        <template #title>
          <span>Testemunha 2</span>
        </template>
        <AssinaturaEmLote tipoTestemunha="3" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import AssinaturaEmLote from './AssinaturaEmLote.vue'
  export default {
    components: {
      AssinaturaEmLote,
    },
    mounted() {},
    methods: {},
  }
</script>
<style></style>
