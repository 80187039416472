<template>
  <b-card >
    <validation-observer ref="formTestemunhas">
      <b-form>
        <b-row>
          <b-col md="4">
            <validation-provider #default="{ errors }" rules="required" name="Gestor"">
              <b-form-group label="Gestor" label-for="Gestor">
                <v-select-pt
                  id="assinaturaTestemunha"
                  :clearable="false"
                  label="name"
                   :options="filteredUserList('gestorId')"
                  :reduce="(option) => option.id"
                  v-model="params.gestorId"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider #default="{ errors }" rules="required" name="Testemunha 1">
              <b-form-group label="Testemunha 1" label-for="Testemunha 1">
                <v-select-pt
                  id="assinaturaTestemunha"
                  :clearable="false"
                  label="name"
                  :options="filteredUserList('testemunha1Id')"
                  :reduce="(option) => option.id"
                  v-model="params.testemunha1Id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider #default="{ errors }" rules="required" name="Testemunha 2">
              <b-form-group label="Testemunha 2" label-for="Testemunha 2">
                <v-select-pt
                  id="assinaturaTestemunha"
                  :clearable="false"
                  label="name"
                  :options="filteredUserList('testemunha2Id')"
                  :reduce="(option) => option.id"
                  v-model="params.testemunha2Id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row align-h="end">
          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="salvar()"
            >
              {{ !isBusy ? 'Salvar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>

          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              style="margin-top: 21px; width: 160px"
              @click="limpar()"
            >
              {{ !isBusy ? 'Limpar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    async mounted() {
      await this.carregarParams()
    },
    data() {
      return {
        isBusy: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        params: {
          gestorId: null,
          testemunha1Id: null,
          testemunha2Id: null,
        },
        userList: [],
      }
    },
    methods: {
      async carregarParams() {
        this.userList = (await useJwt.get(`cadastro/user/listarAdmEUserByInstituto/${this.userData.institutoSelecionado}`)).data
        await useJwt.get(`assinatura/getTestemunhas/${this.userData.institutoSelecionado}`).then((response) => {
          this.params = {
            gestorId: response.data.gestor?.id || null,
            testemunha1Id: response.data.testemunha1?.id || null,
            testemunha2Id: response.data.testemunha2?.id || null,
          };
        })
      },

      async salvar() {
        try {

          const success = await this.$refs.formTestemunhas.validate();
          if (!success) {
            this.$message.error('Preencha todas as testemunhas!');
            return;
          }

          this.isBusy = true
          await useJwt
            .post(`assinatura/postTestemunhas`, {
              gestorId: this.params.gestorId,
              testemunha1Id: this.params.testemunha1Id,
              testemunha2Id: this.params.testemunha2Id,
              institutoId: this.userData.institutoSelecionado,
            })
            .then((response) => {
              this.$message.success(
                 'Registros salvos com sucesso!',
              )
            })
        }
        catch {
          this.$message.error('Erro ao salvar testemunhas!')
        }
        finally {
          
          this.isBusy = false
        }
      },

      limpar() {
        this.params = {
          gestor: null,
          testemunha1: null,
          testemunha2: null,
        }
      },

      filteredUserList(currentField) {
        const selectedIds = [
          this.params.gestorId,
          this.params.testemunha1Id,
          this.params.testemunha2Id,
        ].filter((id, index) => {
          const fields = ['gestorId', 'testemunha1Id', 'testemunha2Id'];
          return id !== null && fields[index] !== currentField; // Exclui o campo atual
        });
        return this.userList.filter((user) => !selectedIds.includes(user.id));
      },
    },
  }
</script>
